/*
  RULES SIZES

 -0: 0
 -1: .25rem
 -2: .5rem
 -3:  1rem
 -4:  1.25rem
 -5:  3rem

*/

.p-0 {
  padding: 0 !important;
}

.py-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

@media screen and (min-width: 1200px) {
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }
}
