:root{
    --rojo-aldo: #e63946;
    --rojo-aldo-dark: #e21f1d;
    --rojo-aldo-transparent: rgba(226, 32, 29, 0.663);
    --font-family: "dinpro", sans-serif;

    --fs-sm: clamp(16px, 2vw, 24px);
    --fs-md: clamp(.85rem, 6vw, 1.125rem);
    --fs-x: clamp(1.25rem, 2.5vw, 2rem);
    --fs-xl: clamp(3.5rem, 7vw, 4rem);
}