.header__logo {
    background-size: cover;
    background-repeat: no-repeat;
    width: 175px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.nav__nav-link {
  font-size: var(--fs-md);
  line-height: 1.4;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 0.25rem;
  -webkit-transition: background-color 0.2s, color 0.2s;
  transition: background-color 0.2s, color 0.2s;
  white-space: nowrap;
  &:hover {
    color: #000;
  }
}

.nav__nav-link--button {
  border-bottom: 1px solid rgba(245, 244, 238, 0.3);
  display: block;
  padding: 1rem 0.5rem;
  border-radius: 0;
}

@media only screen and (min-width: 1100px) {
  .nav__nav-link--button {
    border: none;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  .nav__nav-link--button {
    color: #fff;
    font-size: 0.85rem;
    padding: 0.5rem 1rem;
  }
  .nav__nav-link--button:hover,
  .nav__nav-link--button:focus {
    background-color: rgba(245, 244, 238, 0.16);
  }
}

