/*
  RULES SIZES

 -0: 0
 -1: .25rem
 -2: .5rem
 -3:  1rem
 -4:  1.5rem
 -5:  3rem
 
*/
.my-0{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.my-4{
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}

.my-5{
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}

.mr-0{
    margin-right: 0 !important;
}

.mb-2{
    margin-bottom: .5rem !important;
}

.mb-3{
    margin-bottom: 1rem !important;
}

.mb-5{
    margin-bottom: 3rem !important;
}

@media screen and (min-width: 1200px) {
    .my-md-5{
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-3 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4 {
    margin-right: 3rem !important;
  }

  .ml-md-4 {
    margin-left: 3rem !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

}
