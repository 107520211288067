/* Light */
@font-face {
  font-family: "dinpro";
  src: url("../fonts/DinPro/DINPro-Light_13935.ttf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

/* Regular */
@font-face {
  font-family: "dinpro";
  src: url("../fonts/DinPro/DINPro-Regular_13937.ttf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

/* Medium */
@font-face {
  font-family: "dinpro";
  src: url("../fonts/DinPro/DINPro-Medium_13936.ttf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

/* SemiBold */
@font-face {
  font-family: "dinpro";
  src: url("../fonts/DinPro/DINPro-Bold_13934.ttf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

/* Bolder */
@font-face {
  font-family: "dinpro";
  src: url("../fonts/DinPro/DINPro-Black_13933.ttf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
