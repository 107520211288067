*{
    font-family: "dinpro", sans-serif;
}

body{
    background-color: #f2f2f2;
}

.title{
    font-size: 2.2rem;
    text-align: center;
    text-transform: uppercase;
    word-break: break-word;
    color: var(--rojo-aldo-dark);
    line-height: 1;
    @media screen and (min-width: 562px) {
        font-size: var(--fs-xl);
    }
}

.subtitle{
    text-align: center;
    word-break: break-word;
    font-weight: 400;
    line-height: 1.25;
}

.bold{
    font-weight: 500;
}

.text-center{
    text-align: center;
}

.font-white{
    color: #fff !important
}
