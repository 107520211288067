@use 'padding';
@use 'margin';

@media screen and (min-width: 1200px) {
  .w-md-50 {
    width: 50% !important;
  }

  .d-md-none {
    display: none !important;
  }

  .d-md-block {
    display: block !important;
  }
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

.flex-column {
  flex-direction: column;
}

.flex--align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.align-items-center{
  align-items: center;
}