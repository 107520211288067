@use './header.old';

.header {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 2rem !important;
  align-items: center;
  justify-content: center;
  background-color: var(--rojo-aldo-dark);
  border-bottom: 1px solid rgba(228, 228, 32, 0.315) !important;
  color: #fff;
  padding: 0;
  height: 60px;
  .cta{
    background-color: var(--rojo-aldo-dark);
  }
  @media screen and (min-width: 1100px) {
    
    .cta{
      border: 1px solid #fafafa;
      border-radius: 6px;
    }
  }
}

.burger_button{
  height: 30px;
  width: 30px;
  background-image: url('./img/burger.svg');
  background-size: contain;
  filter: hue-rotate(0deg);
  justify-self: flex-end;

}

.desktop {
  display: none;
  @media screen and (min-width: 900px) {
    display: block;
  }
}

.desplegarMenu {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 61px;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  animation: mover .3s ease-in-out forwards;
  background-color: var(--rojo-aldo-transparent);
  @media screen and (min-width: 900px) {
    transform: translate(0);
  }
}

@keyframes mover {
  0% {
    transform: translateX(100%);
    z-index: 10;
  }
  100% {
    transform: translateX(0);
    z-index: 10;
  }
}

img {
  height: 100%;
  display: block;
}