.nosotros__magazine{
    
    width: 100%;
    display: grid;
    column-gap: 3rem;
    
    .nosotros__first{
        .nosotros__first__container{
            padding: 0 1rem;
            max-width: 1060px;
            @media screen and (min-width: 1200px){
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                column-gap: 2rem;

            }
            img{
                border-radius: 3px;
            }
        }
    }

    .nosotros__first--alter{
        background-color: rgb(15, 15, 15);
        p{
            color: #fff;
        }
    }

    @media screen and (min-width: 768px) {
        width: 90%;
        .nosotros__first--alter{
            background-color: #f2f2f2;
            p{
                color: #000;
            }
        }
        
    }
}

.nosotros__magazine__largo {
    width: 100%;
    background-color: rgb(12 12 12);
    .nosotros__first{
        p{
            color: #fff;
        }
        .nosotros__first__container{
            padding: 0 1rem;
            max-width: 1060px;
            @media screen and (min-width: 768px){
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                align-items: center;
                column-gap: 2rem;

            }
            img{
                border-radius: 3px;
            }
        }
    }
}