.genre {
  background-image: url("img/Gastronomia.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #fff;
  overflow: hidden;
  padding: 7.5rem 0;
  text-align: center;
  .container {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.55);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .genre__intro {
    width: 90%;
  }
  .genre--button {  
    a {
      width: 250px;
      font-weight: 500;
      background-color: var(--rojo-aldo-dark);
    }
  }
}
