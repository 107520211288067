.footer__content{
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: flex-start;
    }
    .footer__item{
        width: 100%;
        text-align: center;
        h2{
            font-size: 1.25rem;
            margin-bottom: .5rem;
        }
    }
}
@media screen and (min-width: 768px) {
    .justify-content-md-between{
        justify-content: space-between !important;
      }
}