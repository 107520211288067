.card-container {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem 0;
    place-items: center;
  }

  @media screen and (min-width: 768px) {
    .card-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }
  }

  
.comprar-link {
    background-color: var(--rojo-aldo-dark);
    text-transform: uppercase;
    border: none;
    color: white;
    padding: 10px 0;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    font-size: var(--fs-md);
    cursor: pointer;
    border-radius: 0 0 5px 5px;
    &:hover {
        background-color: var(--rojo-aldo);
      }
  }