.hp-video__container {
  position: relative;
}
.video__texto {
  h1 {
    position: absolute;
    z-index: 100;
    left: 17%;
    top: 24%;
    color: #fff;
    text-transform: uppercase;
    font-size: 5.5rem;
    width: 72%;
    line-height: normal;
    letter-spacing: 2px;
  }
}
