.newsletter-form {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
    .container--narrow {
      max-width: 1060px;
    }
  }

  

  .container--narrow form{
      background-color: rgba(255, 255, 255, .86);
      border-radius: 10px;
      width: 90%;
      padding: 1rem;
      @media screen and (min-width: 768px){
        
      }
  }
  
  .container--narrow form input{
      background-color: #f2f2f2;
  }
  
  h3 {
    margin-top: 0; /* Eliminar margen superior */
  }
  
  .form-group {
    margin-bottom: 20px; /* Espaciado entre grupos de formulario */
  }
  
  label {
    display: block; /* Mostrar etiquetas en una línea nueva */
  }
  
  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    width: 100%; /* Ocupar todo el ancho disponible */
    padding: 10px; /* Espaciado interno */
    margin-bottom: 10px; /* Espaciado entre campos */
    border: 1px solid #ccc; /* Borde del campo */
    border-radius: 5px; /* Bordes redondeados */
  }
  
  button[type="submit"] {
    width: 100%; /* Ocupar todo el ancho disponible */
    padding: 10px; /* Espaciado interno */
    background-color: rgb(12 12 12); /* Color de fondo */
    color: #fff; /* Color del texto */
    border: none; /* Sin borde */
    border-radius: 5px; /* Bordes redondeados */
    cursor: pointer; /* Cursor de puntero */
    margin-bottom: 1rem;
  }
  
  button[type="submit"]:hover {
    background-color: #603636; /* Color de fondo al pasar el ratón */
  }
  